// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { ToastContainer } from "react-toastify"
import produce from "immer"

// Used components
import Navigation from "../../../components/navigation"
import Content from "../../../components/content"
import Seo from "../../../components/seo"
import CurrentUser from "../../../components/CurrentUser"
import DailyProgress from "../../../components/DailyProgress"
import Tali from "../../../components/Tali"

// Images & Styling
import { BackButton } from "../../../styles/buttons"
import ArrowIcon from "../../../lib/icons/Arrow"
import { DailyTargetLayout } from "../../../styles/common"
import { SpaceButton } from "../../../styles/buttons"

// Fetching data
import { updateCurrentStudentData } from "../../../services/helpers"
import { useStudentStore } from "../../../../store"

// Constants
import { SECONDS_PER_DAY } from "../../../const_values"

// Browser check
const isBrowser = typeof window !== "undefined"

// component
class DailyTargetPage extends Component {
  constructor() {
    super()

    const dataPerDay = {
      isCurrentDay: false,
      isFutureDay: true,
      realLearnedWords: 0,
      realLearnedMins: 0,
      targetLearnedWords: useStudentStore.getState().student?.dailyTarget ?? 20,
    }

    this.state = {
      window: {
        width: 0,
        height: 0,
      },
      inputSessions: [],
      hasAchievedDaily: false,
      data: {
        wordsTotalNumber: 0,
        wordsTotalMin: 0,
        dailyData: Array.from({ length: 5 }).map((_) => ({ ...dataPerDay })),
      },
    }
    this.handleResize = this.handleResize.bind(this)
  }
  // Fehlerschwerpunkt: 3 größten Zahlen in mistakeprognosis --> auf Fehlerkategorie mappen...

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      inputSessions: [],
    })
  }

  renderSectionDynamically = () => {
    const { hasAchievedDaily } = this.state
    const today = new Date().getDay()

    return hasAchievedDaily || today < 1 || today > 5
      ? this.renderVoluntaryTali()
      : this.renderMandatoryTali()
  }

  renderVoluntaryTali = () => {
    const { dailyData } = this.state.data
    const wordsTotalNumber = dailyData.filter((day) => day.isCurrentDay)[0]
      .realLearnedWords

    return (
      <Tali mood="happy" position="top" float={false} messageStyle="glow">
        <h3>You have reached your goal for the day:</h3>
        <p>{wordsTotalNumber} Words</p>
        <Link className="button button--huge exercise" to="/exercise">
          Continue to fill up!
        </Link>
        <SpaceButton
          className="space-button"
          onClick={() => navigate("./space")}
        >
          Take off!
        </SpaceButton>
      </Tali>
    )
  }

  renderMandatoryTali = () => {
    const { wordsTotalNumber, wordsTotalMin } = this.state.data
    const wordsTotalMinRounded = wordsTotalMin.toFixed(1)
    return (
      <Tali mood="happy" position="top" float={false} messageStyle="glow">
        <h3>Done this week:</h3>
        <p>{wordsTotalNumber} Words</p>
        <p>{wordsTotalMinRounded} Minutes</p>
        <Link className="button button--huge exercise" to="/exercise">
          Let‘s fill up for today!
        </Link>
      </Tali>
    )
  }

  checkStudentData = async () => {
    try {
      const login = isBrowser
        ? new URLSearchParams(window.location.search).get("login")
        : ""
      const updateSuccessResult = await updateCurrentStudentData(login)

      if (!updateSuccessResult.success && isBrowser) {
        window.localStorage.removeItem("jwt_student")
        navigate("/student", { replace: true })
      } else if (updateSuccessResult.success) {
        await this.setInputSessions()
      }
    } catch (error) {
      console.error(error)
      window.localStorage.removeItem("jwt_student")
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  componentWillMount() {
    this.checkStudentData()
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
    }
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize)
  }

  setInputSessions = async () => {
    const inputSessions = [
      ...useStudentStore.getState().exercises.covered,
    ].sort((a, b) => a.startTime - b.startTime)
    const dailyTarget = useStudentStore.getState().student.dailyTarget

    this.setState(
      produce((draft) => {
        const { data } = draft
        draft.inputSessions = inputSessions

        const currentDate = new Date()

        const today = currentDate.getDay()

        if (today > 0 && today < 6) {
          data.dailyData[today - 1].isCurrentDay = true
          data.dailyData[today - 1].isFutureDay = false

          const todayStart = new Date(currentDate)
          todayStart.setHours(0)
          todayStart.setMinutes(0)
          todayStart.setSeconds(0)

          const weekBeginningTimestamp =
            todayStart.getTime() - SECONDS_PER_DAY * 1000 * (today - 1)
          const weekBeginning = new Date(weekBeginningTimestamp)

          let i = 0
          while (i < today) {
            data.dailyData[i].isFutureDay = false
            i++
          }

          inputSessions.forEach((s) => {
            const sDateObj = new Date()
            sDateObj.setTime(s.startTime)

            const sWeekDay = sDateObj.getDay()

            const sessionTime = sDateObj.getTime()
            const mondayTime = weekBeginning.getTime()

            if (this.isCurrentWeek(mondayTime, sessionTime)) {
              data.dailyData[sWeekDay - 1].realLearnedMins +=
                s.durationMs / (1000 * 60)
              if (s.correct) {
                data.dailyData[sWeekDay - 1].realLearnedWords += 1
              }
            }
          })

          data.dailyData.forEach((d) => {
            data.wordsTotalNumber += d.realLearnedWords
            data.wordsTotalMin += d.realLearnedMins
          })

          data.dailyData[today - 1].targetLearnedWords = dailyTarget

          if (data.dailyData[today - 1].realLearnedWords >= dailyTarget) {
            draft.hasAchievedDaily = true
          } else {
            this.forceUpdate()
          }
        }
      })
    )
  }

  isCurrentWeek = (mondayTime, sessionTime) => {
    const timestampDifference = sessionTime - mondayTime

    return (
      timestampDifference >= 0 &&
      timestampDifference < 5 * SECONDS_PER_DAY * 1000
    )
  }

  render() {
    const windowHeight = this.state.window.height
    const { dailyData } = this.state.data

    return (
      <div>
        <ToastContainer theme="colored" />
        <Seo title="My Goals" />
        <Navigation>
          <BackButton as={Link} to="/" alt="Back to Login">
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={windowHeight}>
          <DailyTargetLayout>
            <section className="title-area">
              <h1>My Goals</h1>
              <h2>Learn to Write</h2>
            </section>
            <section className="planets-area">
              <DailyProgress weekData={dailyData} scaler={2} />
            </section>
            <section className="tali-area">
              {this.renderSectionDynamically()}
            </section>
          </DailyTargetLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
DailyTargetPage.propTypes = {
  window: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  hasAchievedDaily: PropTypes.bool.isRequired,
  inputSessions: PropTypes.array.isRequired,
  data: PropTypes.shape({
    wordsTotalNumber: PropTypes.number,
    wordsTotalMin: PropTypes.number,
    dailyData: PropTypes.array,
  }).isRequired,
}

export default DailyTargetPage
