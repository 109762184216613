// Basic libraries
import React from "react"
import PropTypes from "prop-types"

// Specific libraries
import BluePlanet from "../../images/planets/blue.png"
import RocketPlanet from "../../images/planets/planet-with-rocket.png"
import WordProgressBar from "../WordProgressBar"
// Styling
import { DailyProgressWrapper } from "./DailyProgressStyling"

// component
const DailyProgressDisplay = ({ weekData, scaler = 1 }) => {
  const planet = BluePlanet
  const rocketPlanet = RocketPlanet

  const monday = weekData[0]
  const tuesday = weekData[1]
  const wednesday = weekData[2]
  const thursday = weekData[3]
  const friday = weekData[4]

  return (
    <DailyProgressWrapper>
      <div className="monday">
        <WordProgressBar
          planet={monday.isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={4 * scaler}
          targetLearnedWords={monday.targetLearnedWords}
          realLearnedWords={monday.realLearnedWords}
          isHidden={monday.isFutureDay}
        />
        <h3>Monday</h3>
      </div>
      <div className="tuesday">
        <WordProgressBar
          planet={tuesday.isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={6 * scaler}
          targetLearnedWords={tuesday.targetLearnedWords}
          realLearnedWords={tuesday.realLearnedWords}
          isHidden={tuesday.isFutureDay}
        />
        <h3>Tuesday</h3>
      </div>
      <div className="wednesday">
        <WordProgressBar
          planet={wednesday.isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={8 * scaler}
          targetLearnedWords={wednesday.targetLearnedWords}
          realLearnedWords={wednesday.realLearnedWords}
          isHidden={wednesday.isFutureDay}
        />
        <h3>Wednesday</h3>
      </div>
      <div className="thursday">
        <WordProgressBar
          planet={thursday.isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={10 * scaler}
          targetLearnedWords={thursday.targetLearnedWords}
          realLearnedWords={thursday.realLearnedWords}
          isHidden={thursday.isFutureDay}
        />
        <h3>Thursday</h3>
      </div>
      <div className="friday">
        <WordProgressBar
          planet={friday.isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={12 * scaler}
          targetLearnedWords={friday.targetLearnedWords}
          realLearnedWords={friday.realLearnedWords}
          isHidden={friday.isFutureDay}
        />
        <h3>Friday</h3>
      </div>
    </DailyProgressWrapper>
  )
}
// component-end

DailyProgressDisplay.propTypes = {
  weekData: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      isCurrentDay: PropTypes.bool,
      isFutureDay: PropTypes.bool,
      realLearnedWords: PropTypes.number,
      targetLearnedWords: PropTypes.number,
    })
  ).isRequired,
  scaler: PropTypes.number,
}

export default DailyProgressDisplay
