// Styling libraries
import styled from "styled-components"

// Layout
export const DailyProgressWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  gap: 0em 1em;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  justify-self: center;
  align-self: center;

  h3 {
    font-size: 2.5vmin;
  }
`
// Layout-end
